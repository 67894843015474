import InitializationError from '@naturehouse/nh-essentials/lib/exceptions/InitializationError';
import PriceListCollection, {
    PriceListCollectionApiData
} from '../../../../collections/price-list/PriceListCollection';
import DetailPriceListModel from '../../../../models/price-list/DetailPriceListModel';
import PricingListRenderer from './DetailPriceListRenderer';
import './DetailPriceList.pcss';

export default class DetailPriceList extends HTMLElement {
    readonly #priceListCollection: PriceListCollection;

    readonly #form: HTMLFormElement | null = null;

    readonly #houseId: string | null = null;

    readonly #extraCostPath: string | null = null;

    set loading(value: boolean) {
        this.classList.toggle('loading-spinner', value);
        this.toggleAttribute('loading', value);
    }

    public constructor() {
        super();

        const formId = this.getAttribute('form-id') ?? '';
        this.#form = document.getElementById(formId) as HTMLFormElement;
        this.#houseId = this.getAttribute('house-id');
        this.#extraCostPath = this.getAttribute('extra-cost-path');
        this.#priceListCollection = PriceListCollection.getInstance();
    }

    public disconnectedCallback(): void {
        PriceListCollection.destroyInstance();
    }

    public async render(): Promise<void> {
        if (this.#form === null || this.#houseId === null || this.#extraCostPath === null) {
            throw new InitializationError({
                detailForm: this.#form,
                houseId: this.#houseId,
                extraCostPath: this.#extraCostPath
            });
        }

        this.loading = true;

        const data = await this.#priceListCollection.retrieve<PriceListCollectionApiData>({
            houseId: this.#houseId,
            searchParams: DetailPriceListModel.parseToSearchParams(new FormData(this.#form))
        });

        if (!data) {
            this.loading = false;
            this.clear();
            return;
        }

        const promotionalDiscount: number = data.priceInformation.discount ?? 0;
        const { base, discount } = data.priceInformation.total;

        this.innerHTML = await PricingListRenderer.render({
            promotionalDiscount: promotionalDiscount,
            totalStayAmount: discount,
            totalStayAmountWithoutDiscount: base,
            extraCostPath: this.#extraCostPath
        });

        this.loading = false;
    }

    public clear(): void {
        this.innerHTML = '';
    }
}

if (!customElements.get('nh-detail-price-list')) {
    customElements.define('nh-detail-price-list', DetailPriceList);
}
